<template>
  <div class="flex min-h-screen flex-col">
    <app-header />
    <div class="flex flex-grow justify-center bg-background">
      <div class="container mb-24 lg:mb-0 xl:px-6">
        <slot />
      </div>
    </div>
    <cookie-statement />
    <search-sector-code />
  </div>
</template>
